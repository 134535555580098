import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Container, Button } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReusableTitle from "../common/ReusableTitle";
import { faTowerBroadcast } from "@fortawesome/pro-solid-svg-icons";
import SetDocumentMeta from "../common/SetDocumentMeta";
import BodyContentBlock from "../common/BodyContentBlock";

const KamathuoServicesWireless = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "5G & Wireless Technology Lawyer | Infrastructure Transactions",
    description:
      "Expert legal guidance for wireless telecom projects. 5G, DAS, Neutral Host, FCC compliance, spectrum licensing, and more. Contact us today!",
    keywords:
      "wireless telecom law, 5G lawyer, DAS attorney, FCC compliance, spectrum licensing, Neutral Host, infrastructure transactions, wireless backhaul, LTE Offload, \
      5G deployment, small cell networks, telecom legal services",
  });

  const bodyContent = [
    {
      contentId: "infrastructure",
      image: "/kth-head-4-wireless.png",
      title: "Wireless Infrastructure",
      text: "We provide comprehensive legal support for 5G and wireless infrastructure deployment, including DAS, small cells, and Neutral Host networks. \
      Our services cover contract drafting and negotiation with landlords, municipalities, and service providers, as well as guidance on zoning laws and regulatory compliance.",
      imagePosition: "right",
      link: "/news-insights?practice_group=wireless",
      linkExternal: false,
    },
    {
      contentId: "fcc-compliance",
      image: "/wireless-regs.png",
      title: "FCC Compliance and Equipment Authorization",
      text: "Our team offers end-to-end legal support for FCC equipment authorization and compliance. We assist with application preparation, submission, \
      and management of ongoing compliance with technical standards. We also provide representation in FCC enforcement actions and audits.",
      imagePosition: "left",
      link: "/news-insights?practice_group=wireless",
      linkExternal: false,
    },
    {
      contentId: "iot-and-emerging-tech",
      image: "/kth_ai_iot.png",
      title: "IoT and Emerging Technologies",
      text: "Stay ahead of the curve with our legal guidance on emerging wireless technologies, including private networks, IoT, and beyond. \
      Our team provides guidance on regulatory compliance and contractual matters specific to IoT and next-generation wireless solutions.",
      imagePosition: "right",
      link: "/news-insights?practice_group=wireless",
      linkExternal: false,
    },
    //@@@TODO!!!!!!!!!!
    // Add Site Registration Services
    // Add Emergency Radio Compliance
    // Consider other services that RFW team can provide w/ little/no lawyer supervision
    // {
    //   contentId: "regulatory-advocacy",
    //   image: "/regulatory-advocacy.png",
    //   title: "Regulatory Advocacy and Policy",
    //   text: "Kama Thuo, PLLC represents clients before the FCC and state regulatory bodies. We provide strategic advocacy on policy matters affecting the wireless industry, \
    //   including rulemaking proceedings, waiver requests, and interpretation of regulations.",
    //   imagePosition: "left",
    //   link: "/news-insights?category=regulatory",
    //   linkExternal: false,
    // },
    // {
    //   contentId: "spectrum-licensing",
    //   image: "/spectrum-licensing.png",
    //   title: "Spectrum Licensing and Transactions",
    //   text: "We guide clients through complex spectrum licensing processes, including auction participation, secondary market transactions, and lease negotiations. \
    //   Our expertise covers FCC spectrum policies, license transfers, and due diligence for spectrum-related mergers and acquisitions.",
    //   imagePosition: "right",
    //   link: "/news-insights?category=spectrum",
    //   linkExternal: false,
    // },
  ];

  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>
          <ReusableTitle
            title="Expert Legal Services for the Wireless Industry"
            isMainHeading={true}
            siteBranding={baseConfig.siteBranding}
            icon={{ faIcon: faTowerBroadcast }}
          />
        </Container>
        <Container>
          <Button
            startIcon={<NewspaperIcon />}
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/news-insights?practice_group=wireless"
          >
            News & Insights: WIRELESS
          </Button>
        </Container>
      </Grid>
      <Grid item xs={12}>
        {bodyContent.map((content, index) => (
          <BodyContentBlock
            key={index}
            content={content}
            baseConfig={baseConfig}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default KamathuoServicesWireless;
