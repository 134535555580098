import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Container,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactGA from "react-ga4";

// Validation schema factory function
const createContactSchema = (isOrganizationRequired, organizationErrorText) => {
  return Yup.object().shape({
    name: Yup.string()
      .max(50, "Name is too long!")
      .required("Name is required"),
    organization: isOrganizationRequired
      ? Yup.string()
          .max(50, organizationErrorText)
          .required(organizationErrorText)
      : Yup.string().max(50, "Organization name is too long!"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number is not valid")
      .max(20, "Phone number is too long!"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    subject: Yup.string()
      .max(100, "Subject is too long!")
      .required("Subject is required"),
    description: Yup.string().max(500, "Description is too long!"),
    recaptcha: Yup.string().required("Please verify you are not a robot"),
  });
};

const ContactUsForm = ({
  baseConfig,
  initialValues = {},
  agreementText,
  formTitle = "Contact Us",
  isOrganizationRequired = false,
  organizationErrorText = "Organization is required",
}) => {
  const navigate = useNavigate();
  const recaptchaRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  // Validation schema
  const ContactSchema = createContactSchema(
    isOrganizationRequired,
    organizationErrorText
  );

  //--------------------------------------------------------------------------
  // Initial Values
  //--------------------------------------------------------------------------

  const defaultInitialValues = {
    name: "",
    email: "",
    subject: "",
    description: "",
  };

  const combinedInitialValues = { ...defaultInitialValues, ...initialValues };

  //--------------------------------------------------------------------------
  // Form Submit
  //--------------------------------------------------------------------------
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Construct the full URL
    const fullUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    try {
      // Send form data to your backend
      const response = await axios.post(
        `${window._env_.REACT_APP_LPIM_API_URL}/patanal_api/contact/`,
        {
          ...values,
          "g-recaptcha-response": values.recaptcha,
          page: fullUrl, // current page's url
        }
      );

      toast.success(response.data.message);
      resetForm();
      recaptchaRef.current.reset();

      // Trigger the GA4 conversion event
      if (window.gtag && baseConfig.googleAnalyticsId) {
        window.gtag("event", "conversion_event_submit_lead_form", {
          send_to: baseConfig.googleAnalyticsId,
          event_category: "Form",
          event_label: "Contact Us Form Submission",
          value: 1,
        });
      }
      // ReactGA.event({
      //   category: "Form",
      //   action: "Submit",
      //   label: "Contact Us Form Submission",
      //   value: 1,
      // });

      // Navigate to home after successful submission
      navigate("/");
    } catch (error) {
      toast.error("Submission failed. Please try again.");
      console.error("Submission failed", error);
    }
    setSubmitting(false);
  };

  //--------------------------------------------------------------------------
  // Render Form
  //--------------------------------------------------------------------------

  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          style={{ color: "#94618E" }}
        >
          {formTitle}
        </Typography>
        <Formik
          initialValues={combinedInitialValues}
          validationSchema={ContactSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched, isSubmitting, resetForm }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Name"
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="organization"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Company/ Organization"
                    error={touched.organization && !!errors.organization}
                    helperText={touched.organization && errors.organization}
                    required={isOrganizationRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Email"
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    required
                    sx={{ my: 0.3 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="phone"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Phone"
                    error={touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                    sx={{ my: 0.3 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="subject"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Subject"
                    error={touched.subject && !!errors.subject}
                    helperText={touched.subject && errors.subject}
                    required
                    sx={{ my: 0.3 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="description"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Description"
                    multiline // Enables multiline input
                    rows={4} // Sets the number of rows the text area will have
                    error={touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                    sx={{
                      "& textarea": {
                        resize: "vertical",
                      },
                      my: 0.3,
                    }}
                  />
                </Grid>

                <Grid container justifyContent="center" sx={{ mt: 2, mb: 1 }}>
                  <Grid item xs={6} align="center">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={window._env_.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={(value) => setFieldValue("recaptcha", value)}
                      size={isMobile ? "compact" : "normal"}
                    />
                  </Grid>

                  {/* Conditionally render agreement section */}
                  {agreementText && (
                    <Grid item xs={6} align="left">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={agreeToTerms}
                            onChange={(e) => setAgreeToTerms(e.target.checked)}
                            color="primary"
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {agreementText}
                          </Typography>
                        }
                      />
                    </Grid>
                  )}
                </Grid>

                {/* Clear & Submit Button Row */}
                <Grid container spacing={3} sx={{ mt: 1 }}>
                  {/* Clear Button */}
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        resetForm();
                        if (recaptchaRef.current) {
                          recaptchaRef.current.reset();
                        }
                      }}
                      disabled={isSubmitting}
                    >
                      Clear
                    </Button>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        backgroundColor: "#88BBD6",
                        "&:hover": { backgroundColor: "#71a5c7" },
                      }}
                      disabled={
                        isSubmitting || (agreementText && !agreeToTerms)
                      }
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default ContactUsForm;
