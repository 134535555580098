import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Container, Button } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import SetDocumentMeta from "../common/SetDocumentMeta";
import BodyContentBlock from "../common/BodyContentBlock";
import ReusableTitle from "../common/ReusableTitle";
import { faNfcSignal } from "@fortawesome/pro-solid-svg-icons";

const KamathuoServicesPatents = ({ baseConfig }) => {
  SetDocumentMeta({
    title:
      "Engineering & Tech Patent Lawyer | Drafting, Prosecution & Analysis",
    description:
      "Secure your innovations with expert patent drafting, prosecution, and strategic portfolio analysis. Our experienced attorneys specialize in engineering and technology patents, including wireless, AI, high-tech electrical, software, and computer systems inventions. Protect your intellectual property and maximize its value.",
    keywords:
      "patent drafting, patent prosecution, patent analysis, patent portfolio counseling, engineering patents, technology patents, wireless patents, AI patents, ML patents, high-tech electrical patents, software patents, computer systems patents, intellectual property lawyer, patent attorney, patent licensing",
  });

  // Define the content for the body
  const bodyContent = [
    {
      contentId: "patent-prosecution",
      image: "/tech-patent-drafting.png",
      title: "Patent Drafting & Prosecution",
      text: "Secure robust patents with Kama Thuo, PLLC. We draft high-quality patents across engineering and technology domains, anticipating litigation \
      challenges to maximize protection and value. We counsel on: \
      <ul> \
      <li> Patent filing strategies </li> \
      <li> Continuation practice </li> \
      <li> Targeted prosecution </li> \
      <li> International protection </li> \
      </ul> \
      We employ strategic prosecution techniques to achieve broad claim scope while minimizing prosecution estoppel risks. Contact us today to safeguard your innovations.",
      imagePosition: "right",
      link: "/news-insights?category=patent-prosecution",
      linkExternal: false,
    },
    {
      contentId: "patent-analysis",
      image: "/patent-analysis.png",
      title: "Patent Analysis & Portfolio Counseling",
      text: "Comprehensive patent analysis services including: \
      <ul> \
      <li> Prior art searches </li> \
      <li> Infringement analysis </li> \
      <li> Patent landscape mapping </li> \
      <li> Freedom to operate (FTO) assessments </li> \
      </ul> \
      Our strategic portfolio counseling optimizes your IP assets, identifying strengths, weaknesses, and monetization opportunities.",
      imagePosition: "left",
      link: "/news-insights?category=patent-analysis",
      linkExternal: false,
    },
    {
      contentId: "patent-licensing",
      image: "/patent-monetization.png",
      title: "Patent Licensing & Monetization",
      text: "Maximize your patent portfolio ROI with our licensing and monetization expertise. We offer: \
      <ul> \
      <li> Patent valuation </li> \
      <li> Licensing strategy development </li> \
      <li> Royalty negotiations </li> \
      <li> Portfolio acquisition/sale guidance </li> \
      <li> Technology Transactions & Agreements </li> \
      </ul> \
      Ideal for investors, financial institutions, and companies seeking to leverage their IP assets.",
      imagePosition: "right",
      link: "/news-insights?category=patent-licensing",
      linkExternal: false,
    },
    {
      contentId: "ai-patent-analysis",
      image: "/kth-ai-in-patent-analysis.png",
      title: "AI-Powered Patent Analysis",
      text: "Cutting-edge AI tools enhance our patent analysis capabilities, offering: \
      <ul> \
      <li> Faster turnaround times </li> \
      <li> Cost-effective solutions </li> \
      <li> Deep portfolio insights </li> \
      </ul> \
      We also provide AI tool selection and implementation guidance, including data labeling and model fine-tuning for legal analysis.",
      imagePosition: "left",
      link: "/ai#ai-patent-analysis",
      linkExternal: false,
    },
    {
      contentId: "ai-patents",
      image: "/ai-patents.png",
      title: "AI & Machine Learning Patents",
      text: "Protect your AI innovations with tailored patent services. Our technical expertise ensures your AI and ML patents are comprehensive and future-proof, \
      maintaining your competitive edge in this rapidly evolving field.",
      imagePosition: "right",
      link: "/ai#ai-patent-analysis",
      linkExternal: false,
    },
    {
      contentId: "wireless-patents",
      image: "/wireless-tech-patents.png",
      title: "Wireless Technology Patents",
      text: "Leaders in wireless patent protection, covering: \
      <ul> \
      <li> Cellular technologies </li> \
      <li> Wi-Fi/WLAN innovations </li> \
      <li> IoT/LPWAN solutions </li> \
      <li> Baseband processor logic, transceiver circuits, and RF front-end blocks </li> \
      <li> Integrated antennas </li> \
      <li> Wireless network and infrastructure equipment </li> \
      <li> SaaS tools for wireless network design and optimization </li> \
      </ul> ",
      imagePosition: "left",
      link: "https://news-insights.kamathuo.com/en-us/patents/patent-analysis/patents-wireless",
      linkExternal: true,
    },
    {
      contentId: "high-tech-electrical-computer",
      image: "/high-tech-hardware.png",
      title: "High-Tech Electrical & Computer Patents",
      text: "Specialized patenting for technology innovations, including: \
      <ul> \
      <li> Embedded communication and control systems </li> \
      <li> Computer hardware, memory, and interconnects </li> \
      <li> Integrated circuits and microchip components </li> \
      <li> Semiconductor devices, processes, manufacturing, and test equipment </li> \
      <li> Consumer and industrial networking equipment </li> \
      <li> Electrical and electronic systems and devices </li> \
      <li> Software innovations </li> \
      </ul> \
      We position your patents strategically in the fast-paced tech market.",
      imagePosition: "right",
      link: "https://news-insights.kamathuo.com/en-us/patents/patent-analysis",
      linkExternal: true,
    },

    {
      contentId: "antenna-patents",
      image: "/antenna-phased-array.png",
      title: "Antenna Patents",
      text: "Expert protection for antenna innovations across diverse applications including: \
      <ul> \
      <li> Cellular, WLAN, and IoT technologies </li> \
      <li> Satellite, microwave, and fixed-wireless access equipment </li> \
      <li> Active and passive distributed antenna systems (DAS) </li> \
      <li> Implantable medical devices </li> \
      <li> UAV/drone systems </li> \
      <li> Embedded communication and control devices </li> \
      </ul> \
      Our deep technical knowledge ensures comprehensive coverage for your antenna technologies.",
      imagePosition: "left",
      link: "https://news-insights.kamathuo.com/en-us/patents/patent-analysis/antenna-analysis",
      linkExternal: true,
    },
    {
      contentId: "sep-patents",
      image: "/seps.png",
      title: "Standards Essential Patents (SEPs)",
      text: "Navigate the complex world of SEPs with our expert guidance: \
      <ul> \
      <li> 3GPP and IEEE standard patent evaluation </li> \
      <li> FRAND licensing counsel </li> \
      <li> SEP quality and validity assessments </li> \
      <li> Strategic positioning in standard-setting activities </li> \
      </ul> \
      Maximize the value of your standards-related innovations.",
      imagePosition: "right",
      link: "/news-insights?category=sep",
      linkExternal: false,
    },
  ];

  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>
          <ReusableTitle
            title="Engineering & Technology Patent Law Services"
            isMainHeading={true}
            siteBranding={baseConfig.siteBranding}
            icon={{ faIcon: faNfcSignal }}
          />
        </Container>
        <Container>
          <Button
            startIcon={<NewspaperIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/news-insights?practice_group=patents"
          >
            News & Insights: PATENTS
          </Button>
          <Button
            startIcon={<HomeRepairServiceIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/patent-ai-resources"
          >
            Patent AI Resources
          </Button>
        </Container>
      </Grid>
      <Grid item xs={12}>
        {bodyContent.map((content, index) => (
          <BodyContentBlock
            key={index}
            content={content}
            baseConfig={baseConfig}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default KamathuoServicesPatents;
